import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import { FaInstagram, FaFacebook, FaTwitter, FaPinterest } from "react-icons/fa";

const Footer = props => {
  const { theme } = props;
  const buildTime = useStaticQuery(query).site.buildTime

  return (
    <React.Fragment>
      <footer className="footer">
        <ul>
          <li>
            <a href="https://instagram.com/adinterestpro" class="btn-social">
              <FaInstagram />
            </a>
          </li>
          <li>
            <a href="https://facebook.com/adinterestpro" class="btn-social">
              <FaFacebook />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/AdInterestPro" class="btn-social">
              <FaTwitter />
            </a>
          </li>
          <li>
            <a href="https://www.pinterest.ca/AdInterestPro/" class="btn-social">
              <FaPinterest />
            </a>
          </li>
        </ul>
        <p className="copyright">&copy; AdInterestPro.</p>
        <p className="info">Last updated: {buildTime}</p>
      </footer>

      {/* --- STYLES --- */}
      <style jsx>{`
        .footer {
          background: ${theme.color.neutral.white};
          padding: ${theme.space.inset.default};
          text-align: center;
          color: ${theme.color.neutral.gray.g};
          font-size: ${theme.font.size.xxs};
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 60px;

          ul {
            padding: ${theme.space.inset.default};
            display: inline-block;
            list-style: none;
            li {
              display: inline-block;
              float: left;
              a {
                padding: ${theme.space.inset.l};
                font-weight: 500;
                font-size: ${theme.font.size.m};
              }
            }
          }

          .copyright {
            margin-bottom: ${theme.space.inset.default};
          }

          .info {
            margin-bottom: ${theme.space.inset.default};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Footer.propTypes = {
  theme: PropTypes.object.isRequired
};

export default Footer;

const query = graphql`
  query Info {
    site {
      buildTime(formatString: "MMM D YYYY")
    }
  }
`
