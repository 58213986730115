module.exports = {
  pathPrefix: "/blog",

  siteUrl: "https://www.adinterestpro.com",

  siteTitle: "AdInterestPro Blog", // <title>
  shortSiteTitle: "AdInterestPro Blog", // <title> ending for posts and pages
  siteDescription: "AdInterestPro Blog",
  siteImage: "logo.png",
  siteLanguage: "en",

  /* author */
  authorName: "AdInterestPro",

  /* header text */
  headerTitle: "AdInterestPro Blog",
  headerSubTitle: "",

  /* manifest.json */
  manifestName: "AdInterestPro Blog",
  manifestShortName: "AIPBLOG", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  gravatarImgMd5: "",
};
